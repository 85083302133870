export enum EventType {
  AlertMessage = 'alertMessage',
  DatesUpdate = 'datesUpdate',
  DateMove = 'dateMove',
  ScheduledDateChange = 'scheduledDateChange',
  ScheduledDateChangeConfirm = 'scheduledDateChangeConfirm',
  SelectFlexDate = 'selectFlexDate',
  SimulateShow = 'simulateShow',
  PreloaderShow = 'preloaderShow',
  CalendarUpdate = 'calendarUpdate',
  ExportStart = 'exportStart',
  StudentAttendanceScheduling = 'studentAttendanceScheduling',
  Notification = 'notification',
  DefaultSchoolChange = 'defaultSchoolChange',
  UserUpdate = 'userUpdate',
  RoomCapUpdate = 'roomCapUpdate'
}
